import React from 'react'
import Home from '../Components/Pages/Home'
import TestPrep from '../Components/Pages/TestPrep'
import NoMatch from '../Components/Pages/NoMatch'
import Feedback from '../Components/Molecules/Feedback'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

const router = createBrowserRouter([
  { path: '/', element: <Home /> },
  { path: '/*', element: <NoMatch /> },
  { path: '/test-prep', element: <TestPrep /> },
])

export default function AppGateway() {
  return (
    <>
      <RouterProvider router={router} />
      <Feedback />
    </>
  )
}
