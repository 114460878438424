import React from 'react'
import { motion, useInView } from 'framer-motion'
import formImage from '../../img/mobileMarketing.svg'

const randDelay = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)

const appearFromLeft = {
  initial: { opacity: 0, x: -100 },
  whileInView: { opacity: 1, x: 0 },
  transition: { duration: 0.5 },
}
export default function Contact({}) {
  const contactRef = React.useRef()
  const isInView = useInView(contactRef)

  React.useEffect(() => {
    let ph = 'Hey, I have a question about ...',
      searchBar = document.querySelector('#message'),
      // placeholder loop counter
      phCount = 0
    // function to print placeholder text in a
    // 'typing' effect
    function printLetter(string, el) {
      // split string into character seperated array
      let arr = string.split(''),
        input = el,
        // store full placeholder
        origString = string,
        // get current placeholder value
        curPlace = input.getAttribute('placeholder'),
        // append next letter to current placeholder
        placeholder = curPlace + arr[phCount]

      setTimeout(function () {
        // print placeholder text
        input.setAttribute('placeholder', placeholder)
        // increase loop count
        phCount++
        // run loop until placeholder is fully printed
        if (phCount < arr.length) {
          printLetter(origString, input)
        }
        // use random speed to simulate
        // 'human' typing
      }, randDelay(50, 150))
    }

    // function to init animation
    function placeholder() {
      searchBar.setAttribute('placeholder', '')
      printLetter(ph, searchBar)
    }
    if (isInView) placeholder()
  }, [isInView])

  return (
    <div ref={contactRef} className="contact mw-90p mt-100px">
      <motion.form {...appearFromLeft} name="contact" netlify="true" method="post">
        <input type="hidden" name="form-name" value="contact" />
        <h3>Contact</h3>
        <label name="name" htmlFor="">
          Name
        </label>
        <input type="text" name="name" />
        <label name="email" htmlFor="">
          Email
        </label>
        <input type="email" name="email" />
        <label name="subject" htmlFor="">
          Subject
        </label>
        <input type="text" name="subject" />
        <label name="message" htmlFor="">
          Message
        </label>
        <textarea name="message" id="message" cols="30" rows="10" />
        <button type="submit" className="button">
          Send
        </button>
      </motion.form>
      <img className="formImage" src={formImage} alt="contact " />
    </div>
  )
}
