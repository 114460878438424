import React from 'react'
import styled from 'styled-components'
import './home.css'
import Landing from '../Atoms/Landing'
import About from '../Atoms/About'
import Product from '../Atoms/Product'
import EmailForm from '../Molecules/EmailForm'
import Reviews from '../Atoms/Review'
import Contact from '../Atoms/Contact'
import Footer from '../Atoms/Footer'
export default function Home() {
  return (
    <Page>
      <Landing />
      <EmailForm />
      {/* <About /> */}
      <Product />
      <Reviews />
      <Contact />
      <Footer />
    </Page>
  )
}

const Page = styled.div`
  display: flex;
  flex-direction: column;
`
