export const tests = [
  {
    name: 'Тест 1',
    state: 'New York',
    questions: [
      {
        question:
          'Водители, которые едят и пьют во время управления автомобилем:',
        answers: [
          'Не совершают ошибок в управлении',
          'Имеют проблемы с медленной ездой',
          'Они лушие водители, потому что сыты',
          'Имеют проблемы в управлении автомобилем',
        ],
        correctAnswer: 3,
      },
      {
        question: 'После того, как проехал поезд, ты должен:',
        answers: [
          'Еще раз проверить наличие других поездов и двигаться, соблюдая осторожность',
          'Дождаться зеленого сигнала',
          'Проехать через железнодорожные пути',
          'Подать звуковой сигнал и проехать',
        ],
        correctAnswer: 0,
      },
      {
        question: 'Одним из правил безопасного вождения является:',
        answers: [
          'Смотреть вперед',
          'Быть внимательным и смотреть по сторонам',
          'Ожидать, что другие водители компенсируют твои ошибки',
          'Быть уверенным, что сможешь избежать опасности в последний момент',
        ],
        correctAnswer: 1,
      },
      {
        question: 'При въезде на эстакаду обычно следует:',
        answers: [
          'Ехать со скоростью, превышающей скорость движущегося впереди потока',
          'Ехать медленнее,чтобы избежать сближения с другими автомобилями',
          'Остановиться, а после медленно въехать в поток движения',
          'Ускориться до скорости потока',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'Если ты остановился у железнодорожного переезда с несколькими путями:',
        answers: [
          'Необходимо дождаться, пока ты не сможешь четко увидеть все пути',
          'Необходимо остановиться на железнодорожных путях и посмотреть, есть ли поезд',
          'Необходимо пересечь его, как только проедет поезд',
          'Необходимо пересечь его, когда освободится один из путей',
        ],
        correctAnswer: 0,
      },
      {
        question:
          'Обязательно соблюдать осторожность при движении рядом с пешеходом который пользуется белой тростью, по причине того, что он:',
        answers: [
          'Глухой',
          'Имеет умственные отклонения',
          'Слепой',
          'Имеет проблемы с передвижением',
        ],
        correctAnswer: 2,
      },
      {
        question: 'Если ты едешь позади мотоцикла, ты должен:',
        answers: [
          'Позволить ему использовать всю полосу',
          'Ехать по обочине позади мотоцикла',
          'Позволить мотоциклу использовать только половину полосы',
        ],
        correctAnswer: 0,
      },
      {
        question: 'Ты используешь безопасную манеру вождения, если ты:',
        answers: [
          'Всегда сохраняешь дистанцию, равную длине автомобиля между твоим автомобилем и автомобилем впереди',
          'Смотришь только на автомобиль спереди',
          'Смотришь по сторонам, чтобы увидеть возможные опасности',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'Входить в поворот следует с разрешенной скоростью и снижать ее на резком участке поворота?',
        answers: [
          'Следует снижать скорость перед вхождением в поворот',
          'Следует постепенно увеличивать скорость перед вхождением в поворот',
          'Следует соблюдать скоростной лимит до поворота, вовремя и после него',
        ],
        correctAnswer: 0,
      },
      {
        question:
          'Чтобы обогнать медленно движущийся автомобиль на дороге с двумя полосами необходимо:',
        answers: [
          'Не пересекать центральную линию',
          'Мигнуть фарами встречному потоку',
          'Использовать обочину',
          'Использовать полосу встречного движения',
        ],
        correctAnswer: 3,
      },
      {
        question: 'Безопасная скорость движения автомобиля:',
        answers: [
          'Та, что указана на знаке ограничения скорости',
          'Мнньше, чем та, что указана на знаке ограничения скорости',
          'Зависит от погодных и дорожных условий',
          'Зависит от технических навыков водителя',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'Когда водители грузовой машины хочет обогнать твой автомобиль, ты должен:',
        answers: [
          'Двигаться с постоянной скоростью или снизить ее',
          'Сменить полосы движения',
          'Переключить скорость',
          'Увеличить скорость',
        ],
        correctAnswer: 0,
      },
      {
        question:
          'Попытка закурить или курение во время управленияа автомобилем:',
        answers: [
          'Не влияют на процесс управления автотранспортным средством',
          'Помогает быть более внимательным',
          'Является отвлекающим фактором',
          'Не отвлекает',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'Проезжая мимо слепого перехода с тростью белого цвета или идущего с собакой-поводырем, ты должен:',
        answers: [
          'Снизить скорость и быть готовым остановиться',
          'Воспользоваться преимуществом проезда',
          'Продолжать движение, как обычно',
          'Быстро уехать',
        ],
        correctAnswer: 0,
      },
      {
        question:
          'Чтобы избежать маневров, совершаемых в последние секунды, следует смотреть в ту точку дороги, где ты будешь через ___.',
        answers: ['5-10 секунд', '10-15 секунд', '15-20 секунд'],
        correctAnswer: 1,
      },
      {
        question:
          'Что ты должен делать, если к тебе приближается автомобиль с включенным дальним светом фар?',
        answers: [
          'Смотреть на приближающийся автомобиль боковым зрением',
          'Зажечь дальний свет и продолжать смотреть прямо',
          'Продолжать смотреть прямо',
          'Закрыть глаза',
        ],
        correctAnswer: 0,
      },
      {
        question:
          'Когда делишь дорогу с грузовым автотранспортом, важно помнить, что грузовикам обычно:',
        answers: [
          'Требуется большая дистанция для остановки',
          'Требуется меньше времени, чем легковым автомобилям, чтобы преодолеть спуск',
          'Требуется меньший радиус поворота, чем легковым автомобилям',
          'Требуется меньше времени, чтобы преодолеть уклон, чем легковым автомобилям',
        ],
        correctAnswer: 0,
      },
      {
        question: 'Обгоняя велосипедиста необходимо:',
        answers: [
          'Подать звуковой сигнал, чтобы предупредить его',
          'Двигаться как можно дальше влево',
          'Продолжать движение в центре полосы',
          'Включить аварийные огни',
        ],
        correctAnswer: 0,
      },
      {
        question: 'Ты можешь припарковать и оставить свой автомобиль',
        answers: [
          'В туннеле',
          'В 30 футах от железнодорожного переезда',
          'Между безопасной зоной и тротуаром',
          'Все ответы неправильны',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'Треугольник оранжевого цвета на задней части автомобиля обозначает, что автомобиль',
        answers: [
          'Перевозит радиоактивные вещества',
          'Делает широкие повороты',
          'Ездит со скоростью ниже, чем остальной поток',
          'Делает частые остановки',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'В обычных условиях безопасное расстояние между твоими автомобилем и автомобилем впереди составляет',
        answers: [
          'Пятьдесят футов',
          'Длина автомобиля',
          'Три секунды за автомобилем впереди',
          'Сто футов',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'В случае движения в зоне, где могут играть дети ты должен быть готов к тому, что они',
        answers: [
          'знают, когда безопасно пересекать дорогу',
          'Остановятся у бордюра, прежде чем пересечь дорогу',
          'Не глядя, перебегут дорогу прямо перед тобой',
          'Не будет пересекать дорогу без сопровождения взрослых',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'Если ты хочешь, покинуть автостраду, но пропустил съезд, ты должен',
        answers: [
          'Ехать до следующего съезда и покинуть автостраду там',
          'Развернуться через разделительную полосу',
          'Съехать на обочину и вернуться к съезду с автострады',
          'Вызвать полицию, чтобы тебя сопроводили до твоего съезда',
        ],
        correctAnswer: 0,
      },
      {
        question:
          'Единственный случай, когда ты не должен останавливаться перед автобусом с мигающими красными огнями и вытянутой в знаке стоп рукой',
        answers: [
          'Ты едешь по противоположной полосе на разделительной полосе автостраде',
          'Ты находишься позади автобуса',
          'Ты не видишь детей',
          'Ты можешь безопасно совершить обгон слева',
        ],
        correctAnswer: 0,
      },
      {
        question:
          'Если твой автомобиль оснащен фронтальными и боковыми подушками безопасности, какое из утверждений верно?',
        answers: [
          'Обязательным является использование только плечевых ремней безопасности',
          'Использование ремней безопасности необязательно',
          'Обязательным является использование всех ремней безопасности',
          'Обязательным является использование только поясного ремня безопасности',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'При пересечении перекрестка ты заметил, что позади тебя едета аварийно-спасательное транспортное средство с включенными сиренами. Твои действия?',
        answers: [
          'Пересечь перекресток и после остановить автомобиль с правой стороны',
          'Уклониться вправо или влево на перекрестке',
          'Остановить автомобиль на перекрестке',
          'Ни один из вариантов',
        ],
        correctAnswer: 0,
      },
      {
        question:
          'Какая манера вождения позволяет  понять, что кто-то находится в состоянии алкогольного опьянения?',
        answers: [
          'Кто-то виляет в своей полосе',
          'Кто-то делает рывки во время начала движения и при торможении',
          'Кто-то едет медленнее, чем остальной поток',
          'Все перечисленное',
        ],
        correctAnswer: 3,
      },
      {
        question: 'Что обозначают знаки оранжевого цвета?',
        answers: [
          'Впереди идет строительство',
          'Полоса для общественных транспортных средств',
          'Обгон разрешен',
          'Регламентирующие знаки',
        ],
        correctAnswer: 0,
      },
      {
        question:
          'Что из нижеперечисленного лучше всего объясняет, что такое “иметь право первостепенного движения”?',
        answers: [
          'Все водители будут соблюдать правила о праве первостепенного движения',
          'Остальные водители уступят тебе дорогу, когда у тебя есть право первостепенного движения',
          'Используй здравый смысл и уступи дорогу другим водителям, если это позволит предотвратить аварию, даже, если право первостепенного движения у тебя',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'Что всегда необходимо делать во время движения в плохих погодных условиях?',
        answers: [
          'Двигаться со скоростью потока',
          'Увеличить безопасное расстояние',
          'Двигаться с разрешенной скоростью',
          'Все перечисленное',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'Когда разрешено парковать автомобиль в туннеле или на мосту?',
        answers: [
          'Если отсутствует знак парковка запрещена',
          'Никогда',
          'Если есть разрешающие знаки',
          'Все ответы неправильны',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'В каком ряду многорядной автострады должны двигаться более медленные водители?',
        answers: [
          'В правом и левом ряду',
          'В центральном ряду',
          'В правом ряду',
          'В левом ряду',
        ],
        correctAnswer: 2,
      },
      {
        question: 'Какую форму имеет знак ограничения скорости',
        answers: ['Треугольник', 'Круг', 'Прямоугольник', 'Восьмиугольник'],
        correctAnswer: 2,
      },
      {
        question:
          '34. Что необходимо сделать, если во время сильной грозы дворники перестали работать?',
        answers: [
          'Снизить скорость и сосредоточиться на задних фарах автомобиля перед тобой',
          'Снизить скорость, высунуть головы в окно, чтобы лучше видеть, и съехать с дороги',
          'Снизить скорость и включить дальний свет и аварийные огни',
          'Медленно продолжать движение в своей полосе до места назначения',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'С кем из перечисленных ниже ты должен обменяться данными водительских удостоверений на месте аварии?',
        answers: [
          'С представителем пожарного департамента',
          'С другими водителями – участниками аварии',
          'Со свидетелями аварии',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'На каком расстоянии до поворота налево ты должен включить указатель поворота',
        answers: ['100 ft', '50ft', '150ft'],
        correctAnswer: 0,
      },
      {
        question:
          'Водители, въезжающие на круговой перекресток или участок с круговым движением',
        answers: [
          'Должны остановиться перед въездом',
          'Должны уступить дорогу водителям, находившимся уже на круговом перекрестке',
          'Имеют преимущество, если едут первыми',
          'Имеют преимущество при наличии двух полос движения',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'Что из перечисленного следует сделать, если ты едешь в таком густом тумане, что не можешь видеть?',
        answers: [
          'Съехать с дороги и выключить свет фар',
          'Использовать ближний свет фар',
          'Подавать повторяющийся звуковой сигнал',
          'Припарковать автомобиль на обочине дороги с включенными габаритными огнями',
        ],
        correctAnswer: 0,
      },
      {
        question:
          'Какое из высказываний о велосипеде и дороге является верным?',
        answers: [
          'Велосипедисты должны находиться рядом с обочиной и двигаться в противоположной потоку направлении',
          'Велосипедисты всегда должны двигаться по тротуару',
          'Велосипедисты никогда не должны покидать велосипедную дорожку',
          'Велосипедисты должны двигаться в направлении потока и находиться как можно дальше СПРАВА',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'Как называется процесс, когда колеса теряют контакт с дорогой при проезде через воду?',
        answers: ['Аквапланирование', 'Сжатие резины', 'Эффект рыбьего хвоста'],
        correctAnswer: 0,
      },
      {
        question:
          'Какой из советов является наилучшим во время движения в тумане?',
        answers: [
          'Всегда использоваться ближний свет фар',
          'Всегда использоваться дальний свет фар',
          'Не водить транспортное средство. Дождаться пока туман рассеется',
        ],
        correctAnswer: 2,
      },
      {
        question: 'В какой момент ты невидим для других водителей?',
        answers: [
          'Когда едешь сбоку от других автомобилей',
          'Когда вблизи перекрестка растут деревья и кустарники',
          'Когда грузовик или транспортное средство больших размеров припарковано на углу перекрестка',
          'Все ответы верны',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'Если ты на двухполосной дороге поворачиваешь налево через двойную желтую линию на частную дорогу, на что стоит обращать внимание?',
        answers: [
          'Транспортные средства, которые двигаются по встречной линии',
          'Транспортные средства позади тебя которые могут попытаться обогнать тебя слева',
          'Все ответы верны',
        ],
        correctAnswer: 2,
      },
      {
        question: 'Когда двойная парковка считается законной?',
        answers: [
          'Чтобы разгрузить товары или высадить пассажиров',
          'Если нет свободных мест для парковки',
          'Никогда',
        ],
        correctAnswer: 2,
      },
      {
        question: 'Когда следует использовать звуковой сигнал?',
        answers: [
          'Когда кто-то переходит тебе дорогу',
          'Чтобы сообщить кому-то об отсутствии у него преимущества на движение',
          'Для предотвращения аварии',
        ],
        correctAnswer: 2,
      },
      {
        question: 'Когда обязательно включить поворотник?',
        answers: [
          'Когда не смотришь через плечо во время смены полос',
          'Всякий раз при смене полос',
          'Когда вокруг есть другие автомобили',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'Если в экстренной ситуации ты вынужден припарковать автомобиль на автостраде, в чем необходимо убедиться?',
        answers: [
          'Двери автомобиля закрыты',
          'Автомобиль виден с расстояния 200 футов во всех направлениях',
          'Аварийные огни включены',
          'Все ответы верны',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'Ты едешь по проселочной дороге, разделенной двойной желтой линией. С твоей стороны линия сплошная. Что из перечисленного запрещено?',
        answers: ['Останавливаться у обочины', 'Выходить', 'Обгон слева'],
        correctAnswer: 2,
      },
      {
        question:
          'Какие меры необходимо принять, если шлагбаум у железнодорожного переезда опущен и не работает',
        answers: [
          'Проехать через шлагбаум',
          'Уведомить местную полицию',
          'Проехать под неисправным шлагбаумом и продолжить движение',
          'Попытаться поднять шлагбаум, чтобы транспорт мог проехать',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'Ты приближаешься к перекрестку, и встречный автомобиль резко сворачивает налево прямо перед тобой. Твои действия',
        answers: [
          'Уклониться влево и попытаться проехать сквозь встречный поток на поперечную улицу слева',
          'Резко затормозить и уклониться в левую часть полосы. Попытайся попасть в промежуток за поворачивающим автомобилем',
          'Свернуть налево на встречную полосу, где только что находился повернувший автомобиль и с силой нажать педаль тормоза',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'Ты едешь в потоке по дороге с двумя или более полосами в каждом направлении. Ты должен',
        answers: [
          'Ехать сбоку или позади другого автомобиля, почти касаясь его, чтобы снизить расход бензина',
          'Избегать движения в чьей-либо слепой зоне. Увеличить или снизить скорость, чтобы остальные водители могли тебя увидеть',
          'Всегда ехать со скоростью немного меньше, чем скоростной лимит, чтобы обезопасить себя от получения штрафа за превышение скорости',
        ],
        correctAnswer: 1,
      },
      {
        question: 'Когда ты должен суметь остановиться, двигаясь в тумане?',
        answers: [
          'В пределах расстояния, которые ты можешь видеть впереди',
          'В 100 футах',
          'Длина футбольного поля',
          'В 5050 футах',
        ],
        correctAnswer: 0,
      },
      {
        question:
          'Какой тип столкновений является наиболее распространенным в зоне строительства?',
        answers: [
          'Лобовое столкновение',
          'Боковое столкновение',
          'Столкновение с рабочей техникой',
          'Наезд сзади',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'Ты должен зажечь ближний свет фар через __ минут после захода солнца и оставить их включенным до _ минут до рассвета',
        answers: ['45,45', '55, 55', '30,30', '20,20'],
        correctAnswer: 2,
      },
      {
        question:
          'Что из увиденного на дороге указывает, что полоса, по которой ты движешься, заставит тебя повернуть?',
        answers: [
          'Мигающую стрелку',
          'Рельефные красные точки',
          'Нарисованную на твоей полосе стрелку',
          'Желтые линии со стороны дороги',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'Если во время движения по автостраде в шине спустило воздух, где находится лучшее для остановки место?',
        answers: [
          'На дороге, если в автомобиле есть аварийные огни',
          'В любом месте, где получится припарковать автомобиль',
          'Посередине дороги',
          'Справой стороны от дороги, где тебя будет видно с расстоянии в 200 футов в любом направлении',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'Какого цвета предупреждающие знаки, как, например, знак крутого поворота или скользкой дороге?',
        answers: [
          'Желтое изображение на черном знаке',
          'Черное изображение на желтом знаке',
          'Черное изображение на белом знаке',
          'Белое изображение на черном знаке',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'Что из перечисленного всегда имеет право первостепенного движения?',
        answers: [
          'Поезда',
          'Слепые пешеходы',
          'Оперативные транспортные средства',
          'Все ответы верны',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'Что является верным в отношении пересечений с путями для легкорельсового транспорта?',
        answers: [
          'Легкорельсовый транспорт имеет больше возможностей остановиться, чем поезд',
          'Легкорельсовый транспорт производит больше шума, чем грузовые поезда',
          'На перекрестках нет шлагбаумов',
          'К ним применимы те же правила, что и к железнодорожными переездами',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'Чего никогда нельзя делать, если позади тебя едет аварийно-спасательное транспортное средство?',
        answers: [
          'Слушать инструкции, данные через громкоговоритель, в случае нарушения правил дорожного движения',
          'Перестраиваться вправо и останавливать автомобиль',
          'Останавливаться на перекрестке',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'Когда ты видишь велосипедиста на дороге с вытянутой рукой вниз или влево рукой, это означает, что велосипедист',
        answers: [
          'Подает знак снижении скорости или остановке',
          'Подает знак о смене полосы движения на левую или о повороте лево',
          'Просто вытягивает руку. Это ничего не значит',
        ],
        correctAnswer: 0,
      },
      {
        question:
          'Что является верным в отношении дорожных штрафов за нарушения в зоне строительства?',
        answers: [
          'Они такие же, как и в других местах',
          'Обычно они выписываются в двойном размере',
          'Составляют половину от штрафов за нарушение в других местах',
          'Ты не можешь получить дорожный штраф в зоне строительства',
        ],
        correctAnswer: 1,
      },
      {
        question: 'Во время управления автомобилем ночью',
        answers: [
          'Разрешено ехать только с включенными габаритными огнями, если зона, по которой ты едешь, хорошо освещена',
          'Разрешено ехать только с габаритными огнями вместо ближнего света фар, если скорость движения низкая',
          'Запрещено движение с габаритными огнями вместо ближнего света фар. Это противозаконно',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'Ты подъезжаешь к перекрестку со знаком СТОП, но без стоп линии. Обзор, для того чтобы увидеть встречный транспорт закрыт. Ты должен',
        answers: [
          'Выехать на перекресток, чтобы увидеть приближающийся транспорт и после ехать дальше',
          'Остановить свой автомобиль достаточно далеко, для того чтобы видеть приближающийся транспорт, но не мешать его движению',
          'Остановить автомобиль на полпути к перекрестку, после ехать далее',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'Какую стратегию нужно использовать для безопасного движения по льду или снегу?',
        answers: [
          'Увеличить скорость, для большего сцепления  дорогой',
          'Использовать четыре колеса',
          'Снизить скорость и увеличить расстояние между автомобилями',
          'Ехать ближе к правому краю дороги',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'Какой процент аварий с летальными исходом связан с алкоголем',
        answers: ['25', '40', '75', '90'],
        correctAnswer: 1,
      },
      {
        question:
          'Ремни безопасности, помимо предотвращения телесных повреждений в авариях, также могут помочь',
        answers: [
          'Не помогают ни при одном из столкновений',
          'Контролировать управление транспортным средством',
          'Предотвратить правильное функционирование подушек безопасности',
          'Запрут тебя внутри автомобиля',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'В каком случае можно проезжать под опущенным шлагбаумом или объезжать его вокруг?',
        answers: [
          'В случае отсутствия приближающегося поезда, и видимость в каждую сторону составляет 400 футов',
          'Всегда',
          'Ни в коем случае!',
          'Если шлагбаум неисправен',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'Ты едешь по многорядной дороге, и другой автомобиль перестраивается в твою полосу прямо перед тобой, подрезав твой путь, ты должен',
        answers: [
          'Подать звуковой сигнал и  резко затормозить, чтобы отдалиться от другого автомобиля',
          'Сохранять спокойствие. Снизь скорость, в случае необходимости избежать столкновения и увеличь безопасное расстояние',
          'Тормози только в случае необходимости избежать столкновения и, некоторое время следуй очень близко за автомобилем',
        ],
        correctAnswer: 1,
      },
      {
        question: 'После того, как проехал поезд, ты должен',
        answers: [
          'Еще раз проверить наличие других поездов и двигаться, соблюдая осторожность',
          'Дождаться зеленого сигнала',
          'Проехать через железнодорожные пути',
          'Подать звуковой сигнал и проехать',
        ],
        correctAnswer: 0,
      },
      {
        question: 'При съезде с автострады, необходимо снизить скорость',
        answers: [
          'На главной дороге, до линии съезда ???????????????????',
          'Как только ты перестроился на лини съезда',
          'Как только ты увидел знак съезда',
        ],
        correctAnswer: 0,
      },
      {
        question:
          'Что означают мигающие красные огни на остановившемся школьном автобусе?',
        answers: [
          'Проезд разрешен, если на дороге нет детей',
          'Проезд запрещен, пока мигают красные огни',
          'Проезд разрешен, если автомобиль движется навстречу автобусу',
          'Проез разрешен, если автобус находится на другой стороне разделенной автомагистрали',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'Ремни безопасности наиболее эффективны для предотвращения телесных повреждений, когда они используются',
        answers: [
          'Водителем',
          'Пассажиром во время путешествия на длинные расстояния',
          'Всеми в автомобиле и во время коротких поездок',
          'Пассажирам и водителю всегда, когда находятся в машине',
        ],
        correctAnswer: 3,
      },
      {
        question: 'При въезде на эстакаду обычно следует',
        answers: [
          'Ехать со скоростью, превышающей скорость движущегося впереди потока',
          'Ехать медленнее, чтобы избежать опасности сближения с другими автомобилями',
          'Остановиться и после этого медленно въехать в поток движения',
          'Ускороиться до скорости потока',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'No-Fault Law постановляет, что водители должны иметь страховку минимум на $10.000 для Personal Injury Protection (PIP) и страховую защиту на $10.000 для Property Damage Liability (PDL)',
        answers: ['Да', 'Нет'],
        correctAnswer: 0,
      },
      {
        question: 'Знак ПАРКОВКА ЗАПРЕЩЕНА, в определенном месте означает,что',
        answers: [
          'Здесь всегда запрещена остановка автомобиля',
          'Ты можешь остановить здесь свой автомобиль на время посадки и высадки пассажиров',
          'Ты можешь припарковать здесь свой автомобиль, если водитель останется в нем',
          'Можешь оставить свой автомобиль на время не менее 5 минут',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'Водители, въезжающие на круговой перекресток, или участок с круговым движением',
        answers: [
          'Должны остановиться перед въездом',
          'Должны уступить дорогу водителям, уже находившимся на круговом перекрестке',
          'Имеют преимущество, если едут первыми',
          'Имеют преимущество при наличии двух полос движения',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'Штраф удваивается, если нарушения совершаются в зоне школы или строительной площадки',
        answers: ['Да', 'Нет'],
        correctAnswer: 0,
      },
      {
        question: 'При обгоне на многополосной автостраде',
        answers: [
          'Убедись, что полоса для обгона свободна',
          'Обгоняй только по правой полосе',
          'Берегись встречного движения',
          'Не нужно подавать знак для смены полосы движения',
        ],
        correctAnswer: 0,
      },
      {
        question:
          'Если ты припарковался параллельно  бордюру, ты можешь выйти и машины на сторону движения трафика',
        answers: [
          'В дневные часы, когда другие водители могут тебя видеть',
          'Когда на ближайшем к тебе перекрестке на светофоре загорается красный свет',
          'Если ты не помешаешь водителям с противоположной стороны',
          'Если сначала ты используешь ближний свет фар',
        ],
        correctAnswer: 2,
      },
      {
        question: 'Попытка закурить и курение во время управления автомобилем',
        answers: [
          'Не влияет на процесс управления автотранспортным средством',
          'Помогает быть внимательным',
          'Являются отвлекающим фактором',
          'Не отвлекает',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'На двух полосной дороге, дороге с двусторонним движением или на автостраде следует начинать левый поворот',
        answers: [
          'Рядом с центральной линией',
          'Рядом с внешней линией',
          'От центра полосы',
          'В любом месте полосы',
        ],
        correctAnswer: 0,
      },
      {
        question:
          'Какое нарушение правил является самым распространенным среди водителей?',
        answers: [
          'Проезд без остановки перед СТОП знаком',
          'Превышение скорости',
          'Пользование мобильным телефоном',
          'Проезд в запрещенных местах',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'Чтобы обогнать медленно движущийся автомобиль на дороге с двумя полосами необходимо',
        answers: [
          'Не пересекать центральную линию',
          'Мигнуть фарами встреному потоку',
          'Использовать обочину',
          'Использовать полосу встречного движения',
        ],
        correctAnswer: 3,
      },
      {
        question: 'Какое из высказываний о СЛЕПОЙ ЗОНЕ верно?',
        answers: [
          'Их нет, ели каждой стороны автомобиля установлены внешние зеркала',
          'У длинных грузовиков СЛЕПЫЕ ЗОНЫ, больше, чем у пассажирских автомобилей',
          'Проверить СЛЕПЫЕ зоны можно в зеркале заднего вида',
        ],
        correctAnswer: 1,
      },
      {
        question: 'Знак минимальной скорости предназначен для',
        answers: [
          'Поддержания бесперебойного движения потока',
          'Демонстрации актуального состояния местных дорог',
          'Проверить будущую необходимость в дорожных знаках',
          'Обеспечить безопасность пешеходов',
        ],
        correctAnswer: 0,
      },
      {
        question:
          'Дорожный гипнотизм - это условие, которое может быть результатом того, что человек',
        answers: [
          'Смотрел на дорогу в течение долгого периода времени',
          'Часто останавливался для отдыха',
          'Много спал в ночь перед поездкой',
          'Ехал по дороге короткими дистанциями',
        ],
        correctAnswer: 0,
      },
      {
        question:
          'Если ты хочешь догнать и опередить другой автомобиль, ты должен',
        answers: [
          'Дождаться сигнала от другого водителя',
          'Быстро сменить полосу движения, чтобы другой водитель смог увидеть тебя',
          'Подать сигнал поворота и обогнать, когда это будет безопасно',
          'Ехать близко позади него, чтобы тебе понадобилось меньше времени на обгон',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'Если ты попал в автомобильную аварию, в твои обязанности входит',
        answers: [
          'Остановиться',
          'Сообщить в полицию об аварии',
          'Переставить свой автомобиль, если он блокирует движение',
          'Все ответы верны',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'Самая важная вещь, которую следует помнить о скорости и поворотах, это',
        answers: [
          'Входить в поворот следует с разрешенной скоростью, и снижать ее на резком участке поворота',
          'Следует снижать скорость перед вхождением в поворот',
          'Следует постепенно увеличивать скорость перед вхождением в поворот',
          'Следует соблюдать скоростной лимит до поворота, во время и после него',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'ТЫ ожидаешь поворота направо на перекрестке с несколькими полосами, а противоположный трафик закрывает тебе обзор, ты должен',
        answers: [
          'Быстро увеличить скорость, как только первая полоса, которую ты должен пересечь - освободится',
          'Подождать, пока не сможешь увидеть полосы, которые ты должен пересечь, прежде чем приступать к повороту',
          'Подождать, пока водители из противоположного потока подаст тебе сигнал проехать перекресток',
          'Продвигайся в нужную полосу по мере ее освобождения',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'Если во время движения по автостраде в шине спустило воздух, где находится лучшее для остановки место?',
        answers: [
          'На дороге, если в автомобиле есть аварийные огни',
          'Влюбом месте, где получится припарковать автомобиль',
          'Посередине дороги',
          'С Правой стороны от дороги, где тебя будет видно с расстояния в 200 футов в любом направлении',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'В обычных условиях безопасное расстояние между твоим автомобилем и автомобилем впереди составляет',
        answers: [
          'Пятьдесят футов',
          'Длину автомобиля',
          'Три секунды за автомобилем движущегося впереди тебя',
          'Сто футов',
        ],
        correctAnswer: 2,
      },
      {
        question: 'При обгоне другого автомобиля необходимо',
        answers: [
          'Сохранять безопасную дистанцию за автомобилем, который необходимо опередить',
          'Обозначить сигналом намерение перестроения',
          'Вернуться на правильную часть дороги после чего, опережающий автомобиль появится в зеркале заднего вида',
          'Все ответы верны',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'Ты можешь покинуть дорожное покрытие, чтобы опередить другой автомобиль',
        answers: [
          'Если обочина достаточно широкая, чтобы вместить твой автомобиль',
          'Если автомобиль перед тобой совершает левый поворот',
          'Ни в коем случае!',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'Как правило, следует опережать автомобили, едущие в том же направлении, что и ты',
        answers: [
          'Справа',
          'Слева',
          'Только, когда водитель даст знак, что это безопасно',
          'Каждый раз, когда есть такая возможность',
        ],
        correctAnswer: 1,
      },
      {
        question: 'Водитель, принимающий безрецептурные медикаменты, должен',
        answers: [
          'Прочитать инструкции к медикаментам, прежде  чем садиться за руль',
          'Продолжать движение',
          'Управлять автомобилем только в дневные часы',
        ],
        correctAnswer: 0,
      },
      {
        question:
          'Прежде чем покинуть место параллельной к бордюру парковки ты должен',
        answers: [
          'Подать звуковой сигнал',
          'Включить сигнальные огни',
          'Повернуть голову, чтобы проверить отсутствие трафика',
          'Проверить в зеркале заднего вида отсутствие трафика',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'Ты едешь в потоке по дороге с двумя или более полосами в каждом направлении. Ты должен',
        answers: [
          'Ехать сбоку или позади другого автомобиля, почти касаясь его, чтобы снизить расход бензина',
          'Избегать движения в чьей-либо слепой зоне. Увеличить или снизить скорость, чтобы остальные водители могли тебя видеть.',
          'Всегда ехать со скоростью немного меньше, чем скоростной лимит, чтобы обезопасить себя от получения штрафа за превышение скорости.',
        ],
        correctAnswer: 1,
      },
      {
        question: 'Развороты в жилой зоне разрешены',
        answers: [
          'На улице с односторонним движением в направлении зеленой стрелки',
          'Если поблизости нет приближающихся автомобилей',
          'Через две группы двойной сплошной желтой линии',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'The Implied Consent Law обозначает, что ты согласен сдать полицейскому на анализ кровь, мочу или слюну, если он подозревает, что ты находишься за рулем в состоянии алкогольного или наркотического опьянения',
        answers: ['Да', 'Нет'],
        correctAnswer: 0,
      },
      {
        question: 'Водительские права могут быть ЗАМОРОЖЕНЫ в случае',
        answers: [
          'Не предоставление права проезда школьному автобусу',
          'Отсутствия страховка на автомобиль',
          'Неуплаты алиментов',
          'Все ответы верны',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'При обгоне автомобиля, ты должен вернуться в свою полосу, когда',
        answers: [
          'Видишь передний бампер другого автомобиля в зеркале',
          'Включил сигнал поворота',
          'Включи ближний свет фар',
          'Видишь включение ближнего света другого автомобиля',
        ],
        correctAnswer: 0,
      },
      {
        question: 'Ты не должен парковать автомобиль',
        answers: [
          'На обочине дороги в экстренных ситуациях',
          'У бордюра, окрашенного в красный цвет',
          'В пределах 100 футов от здания начальной школы',
        ],
        correctAnswer: 1,
      },
      {
        question: 'Автомобиль запрещено парковать в следующих местах',
        answers: [
          'В 15 футах от пожарного гидранта',
          'Напротив подъездных дорожек',
          'На тротуарах',
          'Все ответы верны',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'Ты можешь опередить другой автомобиль справа, если он ожидает',
        answers: [
          'Поворота направо',
          'Поворота налево',
          'Припарковать автомобиль у бордюра',
          'Повернуть на дорогу справа',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'Водители, которые едят и пьют во время управления автомобилем',
        answers: [
          'Не совершают ошибок в управлении',
          'Они лучшие водители, потому что сыты',
          'Имеют проблемы в управлении автомобилем',
        ],
        correctAnswer: null,
      },
      {
        question: 'Ты можешь припарковать и оставить свой автомобиль',
        answers: [
          'В туннеле',
          'В 30 футах от железнодорожного переезда',
          'Между безопасной зоной и тротуаром',
          'Все ответы неправильны',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'Ты едешь в центральном ряду трехрядной автомагистрали. Автомобиль начинает опережать тебя справа. Действия этого водителя являются',
        answers: [
          'Неправильными, потому что ОБГОН СЛЕВА является четким правилом',
          'Неправильным, потому что он проезжает в твоей слепой зоне',
          'Верным, если это происходит на дороге с ограниченным въездом',
        ],
        correctAnswer: 0,
      },
      {
        question:
          'Водитель обязан следовать следующим законам при страховании автотранспортного средства',
        answers: [
          'Financial Responsibility Law',
          'No-Fault law',
          'Homeowner’s Insurance',
          'No-Fault Law и Financial Responsibility Law',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'При обгоне автомобиля, ты должен вернуться в свою полосу, когда',
        answers: [
          'Видишь передний бампер другого автомобиля в зеркале',
          'Включил сигнал поворота',
          'Включил ближний свет фар',
          'Видишь включение ближнего света другого автомобиля',
        ],
        correctAnswer: 0,
      },
      {
        question:
          'Ты можешь опередить другой автомобиль справа, если он ожидает',
        answers: [
          'Поворота направо',
          'Поворота налево',
          'Припарковать автомобиль у бордюра',
          'Повернуть на дорогу справа',
        ],
        correctAnswer: 1,
      },
      {
        question: 'При обгоне другого автомобиля необходимо',
        answers: [
          'Сохранять безопасную дистанцию за автомобилем, который необходимо опередить',
          'Обозначить сигналом намерение перестроения',
          'Вернуться на правильную часть дороги после того, как опережаемый автомобиль появится в экране заднего вида',
          'Все ответы верны',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'В каком ряду многорядной автострады должны двигаться более медленные водители?',
        answers: [
          'В правом или левому ряду',
          'В центральном ряду',
          'В правом ряду',
          'В левом ряду',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'На знаке СТОП ты должен уступить дорогу всем пешеходам и уже находящимся там водителям',
        answers: ['Да', 'Нет'],
        correctAnswer: 0,
      },
      {
        question:
          'Согласно закону, только пассажиры на переднем сидении должны пользоваться ремнями безопасности',
        answers: ['Да', 'Нет'],
        correctAnswer: 1,
      },
      {
        question:
          'Как правильно, следует опережать автомобили, едущие в том же направлении, что и ты',
        answers: [
          'Справа',
          'Слева',
          'Только когда водитель даст знак, что это безопасно',
          'Каждый раз, когда есть такая возможность',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'Ремни безопасности наиболее эффективны для предотвращения телесных повреждений, когда они используются',
        answers: [
          'Водителем',
          'Пассажиром во время путешествия на долгие расстояния',
          'Всем в автомобиле во время коротких поездок',
          'Пассажиром и водителем всегда, когда находятся в машине',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'Предполагая, что улица ровная, что ты должен сделать, совершив параллельную парковку между двумя автомобилями?',
        answers: [
          'Повернуть передние колеса к бордюру',
          'Убедиться, что твой автомобиль практически соприкасается с автомобилем позади',
          'Проехать вперед насколько это возможно',
          'Выровнять передние колеса и оставить пространство между автомобилями',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'Если ты попал в автомобильную аварию, в твои обязанности входит',
        answers: [
          'Остановиться',
          'Сообщить в полицию об аварии',
          'Переставить свой автомобиль, если он блокирует движение',
          'Все ответы верны',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'Что будет с твоим водительским удостоверением, в случае отказа сдать химический анализ (дыхание или кровь)?',
        answers: [
          'Не будет никаких обвинительных доказательств',
          'Тебя не могут арестовать за вождение в нетрезвом виде',
          'Водительские права могут изъять',
          'Все ответы неправильны',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'Твои водительские права могут быть аннулированы за 30 дней, если ты получишь',
        answers: [
          '12 баллов за один календарный год',
          '20 баллов за один календарный год',
          '25 баллов за один календарный год',
          '30 баллов за один календарный год',
        ],
        correctAnswer: 0,
      },
      {
        question: 'При въезде на эстакаду обычно следует',
        answers: [
          'Ехать со скоростью превышающей скорость движущегося впереди автомобильного потока',
          'Ехать медленнее, чтобы избежать других автомобилей',
          'Остановиться, после медленно въехать в поток движения',
          'Ускориться до скорости транспортного потока',
        ],
        correctAnswer: 3,
      },
      {
        question: 'Водитель, принимающий безрецептурные медикаменты, должен',
        answers: [
          'Прочитать инструкции к медикаментам, прежде чем садиться за руль',
          'Вместо лекарств употребить алкоголь',
          'Продолжать движение',
        ],
        correctAnswer: 0,
      },
      {
        question: 'В какой момент ты невидим для других водителей?',
        answers: [
          'Когда едешь сбоку от других автомобилей',
          'Когда вблизи перекрестка растут деревья и кустарники',
          'Когда грузовик или транспортное средство больших размеров припарковано близко к перекрестку',
          'Все ответы верны',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'Какого цвета предупреждающие знаки, как, например, знак крутого поворота или скользкой дороги?',
        answers: [
          'Желтое изображение на черном знаке',
          'Черное изображение на желтом знаке',
          'Черное изображение на белом знаке',
          'Белое изображение на черном знаке',
        ],
        correctAnswer: 2,
      },
      {
        question: 'Автомобиль запрещено парковать в следующих местах',
        answers: [
          'Менее 15 футов от пожарного гидранта',
          'Напротив подъездных дорожек',
          'На тротуарах',
          'Все ответы верны',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'Водитель может припарковать автомобиль на месте для людей с ограниченными возможностями, если на машине есть знак, обозначающий инвалида',
        answers: [
          'Независимо от того, кто находится за рулем автомобиля',
          'Только, если водитель имеет инвалидность',
          'В машине находится человек с инвалидностью, чье имя указано в удостоверении',
          'Если кто-то из ближайших родственников водителя имеет инвалидность',
        ],
        correctAnswer: 0,
      },
      {
        question:
          'Желтая непрерывная линия рядом с прерывистой желтой линией обозначает, что автомобиль',
        answers: [
          'Может совершать обгон с двух сторон',
          'Может обгонят со стороны прерывистой линии',
          'Может обгонять со стороны сплошной линии',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'Знак ОСТАНОВКА ЗАПРЕЩЕНА, размещенный в определенном месте, обозначает',
        answers: [
          'Остановка автомобиля в этом месте всегда запрещена',
          'Парковка разрешена, если водитель останется в автомобиле',
          'Остановка разрешена на время посадки и высадки пассажиров',
        ],
        correctAnswer: 0,
      },
      {
        question: 'При параллельной к бордюру парковке на ровной улице',
        answers: [
          'Передние колеса должны быть повернуты по направлению к дороге',
          'Колеса автомобиля должны находиться в 18 дюймах от бордюра',
          'Одно из колес автомобиля должно соприкасаться с бордюрным камнем',
          'Остановка разрешена на время загрузки или разгрузки автомобиля',
        ],
        correctAnswer: 1,
      },
      {
        question: 'Автомобиль позади тебя начинает обгон. Ты должен',
        answers: [
          'Сохранять скорость движения, не препятствуя движению трафика',
          'Взять правее и остановиться, чтобы он смог проехать',
          'Немного снизить скорость и остаться в своей полосе движения',
          'Подать звуковой сигнал, чтобы он проезжал',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'В местах для парковки людей с ограниченными возможностями люди без ограниченных возможностей',
        answers: [
          'Могут парковать автомобиль в экстренных ситуациях',
          'Не могут парковать автомобиль, но могут останавливаться в экстренных ситуациях',
          'Не должны останавливаться и парковать свой автомобиль',
        ],
        correctAnswer: 2,
      },
      {
        question: 'Ты не должен парковать автомобиль',
        answers: [
          'На обочине дороги в экстренных ситуациях',
          'У бордюра, окрашенного в красный цвет',
          'В пределах 100 футов от здания начальной школы',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'Ты едешь в центральном ряду трехрядной автомагистрали. Автомобиль начинает опережать тебя справа. Действия этого водителя являются',
        answers: [
          'Неправильными, потому что ОБГОН СЛЕВА является четким правилом',
          'Неправильными, потому что он проезжает в твоей слепой зоне',
          'Верными, если это происходит на дороге с ограниченным въездом',
          'Верными, если отсутствует знак, запрещающий обгон справа',
        ],
        correctAnswer: 3,
      },
      {
        question: 'Ты никогда не должен парковать автомобиль',
        answers: [
          'На улице с односторонним движением',
          'У входа в здание',
          'На пешеходном переходе',
          'Менее 20 футов (6м) от пожарного гидранта',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'Ты можешь пересечь двойную желтую линию для опережения, если',
        answers: [
          'Автомобиль перед тобой берет правее, чтобы позволить тебе совершить опережение',
          'Желтая линия с твоей стороны прерывистая',
          'Желтая линия с другой стороны дороги прерывистая',
          'Ничего из вышеперечисленного',
        ],
        correctAnswer: 1,
      },
      {
        question: 'Если ты планируешь опередить другой автомобиль, ты должен',
        answers: [
          'Не думать, что другой водитель предоставит тебе пространство, чтобы вернуться в твою полосу',
          'Ожидать, что другой водитель позволит тебе опередить его, если ты покажешь сигнал поворота',
          'Ожидать, что другой водитель будет сохранять безопасную дистанцию',
        ],
        correctAnswer: 0,
      },
      {
        question: 'В каких ситуациях всегда запрещен обгон?',
        answers: [
          'Машина впереди совершает поворот налево',
          'Ты находишься на улице с односторонним движением с двумя полосами',
          'Автомобиль впереди остановился, чтобы пропустить пешехода  на пешеходном переходе',
          'Автомобиль впереди планирует совершить парковку параллельно к бордюру',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'The Implied Consent Law обозначает, что ты согласен сдать полицейскому на анализ кровь, мочу или слюну, если он подозревает, что ты находишься за рулем в состоянии алкогольного или наркотического опьянения',
        answers: ['Да', 'Нет'],
        correctAnswer: 0,
      },
      {
        question: 'Когда следует использовать звуковой сигнал?',
        answers: [
          'КОгда кто-то переходит тебе дорогу',
          'Чтобы сообщить кому-то об отсутствии у него преимущества на движение',
          'Для предотвращения аварии',
        ],
        correctAnswer: 2,
      },
      {
        question: 'Развороты в жилой зоне разрешены',
        answers: [
          'На улице с односторонним движением в направлении зеленой стрелки',
          'Если поблизости нет приближающихся автомобилей',
          'Через две группы двойной сплошной желтой линии',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'Водительские права могут быть ЗАМОРОЖЕНЫ (не действительны) в случае',
        answers: [
          '12 баллов в период за 12 месяцев',
          '18 баллов за период 18 месяцев',
          '24 балла в период за 36 месяцев',
          'Все ответы верны',
        ],
        correctAnswer: 3,
      },
      {
        question: 'Водительские права могут быть ЗАМОРОЖЕНЫ в случае',
        answers: [
          'Не предоставления права проезда школьному автобусу',
          'Отсутствия страховки на автомобиль',
          'Неуплаты алиментов',
          'Все ответы верны',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'Если ты припарковался параллельно к бордюры, ты можешь выйти из машины на сторону движения трафика',
        answers: [
          'В дневные часы, когда другие водители могут тебя видеть',
          'Когда на ближайшем к тебе перекрестке на светофоре загорается красный свет',
          'Если ты не помешаешь водителям с противоположной стороны',
          'Если сначала ты используешь ближний свет фар',
        ],
        correctAnswer: 2,
      },
      {
        question: 'При съезде с автострады необходимо снизить скорость',
        answers: [
          'На главной дороге, до линии съезда',
          'Как только ты перестроился на линию съезда',
          'Как только ты увидел знак съезда',
          'Как только увидел общий информационный знак для обозначения парковки',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'Люди, управляющие автомобилем в состоянии алкогольного опьянения рискуют',
        answers: [
          'Получить большой штраф',
          'Лишиться водительской лицензии',
          'Оказаться в тюремном заключении',
          'Все ответы верны',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'Какое из высказываний о велосипедистах и дороге является верным?',
        answers: [
          'Велосипедисты должны находиться рядом с обочиной и двигаться в противоположном потоку направлении',
          'Велосипедисты всегда должны находиться на тротуаре',
          'Велосипедисты никогда не должны покидать велосипедную дорожку',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'Если ты употребляешь алкоголь в компании, что позволит обеспечить безопасность вождения?',
        answers: [
          'Выпить кофе перед тем, как спадиться за оруль',
          'Отправиться домой с другом, который не употреблял алкоголь',
          'Принять холодный душ',
          'Перестать выпивать за полтора часа до того, как сесть за руль',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'Какой из следующих вариантов оказывает влияние на эффекты от алкоголя?',
        answers: [
          'Количество пищи в желудке',
          'Вес человека',
          'Сколько времени прошло между напитками',
          'Все три варианта верные',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'В случае вождения в нетрезвом виде следует учитывать влияние алкоголя на',
        answers: ['Координацию', 'Зрение', 'Суждение', 'Все перечисленное'],
        correctAnswer: 3,
      },
      {
        question:
          'Какой эффект оказывает употребление алкоголя и предписанных или безрецептурных лекарственных средств?',
        answers: [
          'Медикаменты снижают влияние алкоголя',
          'Алкоголь помогает медикаментами в лечении гриппа',
          'Данная комбинация может усугубить действие алкоголь',
          'Никакого эффекта, это разные вещества',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'Уровень содержания алкоголя в крови (ВАС) от каждого из условий приведенных ниже, за исключением одного',
        answers: [
          'Веса',
          'Количество времени между напитками',
          'Физическая форма человека',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'Какой способ является наиболее эффективным для снижения уровня алкоголя в крови?',
        answers: [
          'Выпить кофе',
          'Физические упражнения',
          'Дать телу время, чтобы вывести алкоголь из организма',
          'Холодный душ',
        ],
        correctAnswer: 2,
      },
      {
        question: 'Чего не произойдет после употребления алкоголя?',
        answers: [
          'Рефлексы и время реагирования замедлятся',
          'Способность определять скорость и расстояния искажается',
          'Снижается бдительность',
          'Улучшается концентрация внимания',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'Как влияет алкоголь на способность управления транспортным средством и суждения?',
        answers: [
          'Улучшает способности управления транспортным средством, но искажают суждения',
          'Ухудшают способности управления транспортным средством и искажают суждения',
          'Не оказывают никакого влияния на способности управление транспортным средством и суждения',
          'Не влияет на суждения, но ухудшают способности управления транспортным средством',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'Каковы последствия вождения под воздействием алкоголя или других наркотических веществ',
        answers: [
          'Возможен арест',
          'Обязательный к уплате штраф',
          'Лишение водительских прав',
          'Все варианты верны',
        ],
        correctAnswer: 3,
      },
      {
        question:
          'Какой тип лекарственных средств, помимо алкоголя, будет влиять на способность управлять транспортным средством',
        answers: [
          'Все противоаллергенные препараты',
          'Марихуана',
          'Противогриппозные препараты',
          'Все варианты верные',
        ],
        correctAnswer: 3,
      },
      {
        question: 'Химический анализ используется для измерения',
        answers: [
          'Скорость реакции',
          'Содержания алкоголя в крови',
          'Зрения',
          'Способности управлять транспортным средством',
        ],
        correctAnswer: 1,
      },
      {
        question: 'В среднем тело человека может усвоить 12 унций пива за',
        answers: ['Один час', 'Один день', 'Пять минут', 'Пять часов'],
        correctAnswer: 0,
      },
      {
        question:
          'Управление транспортным средством в состоянии алкогольного опьянения лицам, не достигшим 21 года, является противозаконным, если уровень алкоголя в крови составляет',
        answers: [
          '0.08% и более',
          '0.01% и более',
          '0.05% и более',
          'Ничего из вышеперечисленного',
        ],
        correctAnswer: 1,
      },
      {
        question:
          'Как влияет комбинация алкоголя и других лекарственных препаратов в крови?',
        answers: [
          'Эффекты от обоих возрастают',
          'Не влияет на способность управлять транспортным средством',
          'Влияние лекарств или наркотиков снижается',
          'Влияние алкоголя снижается',
        ],
        correctAnswer: 0,
      },
      {
        question:
          'Какое из утверждений о содержании алкоголя в крови является верным?',
        answers: [
          'Алкотестер - это способ определения уровня содержания алкоголя в крови человека.',
          'Химический анализ уровня алкоголя в крови нужен для обвинения человека в употреблении алкоголя',
          'Уровень алкоголя  в крови снижается после физических упражнений',
          'Кофе или холодный душ снижают уровень алкоголя в крови',
        ],
        correctAnswer: 0,
      },
      {
        question: 'Кофе, выпитый после алкоголя',
        answers: [
          'Снижает уровень алкоголя в крови',
          'Отменяет действие алкоголя',
          'Не оказывает никакого влияния на уровень содержания алкоголя в крови',
          'Увеличивает уровень алкоголя в крови',
        ],
        correctAnswer: 2,
      },
      {
        question:
          'Какое содержание алкоголя в крови считается противозаконным в штате Нью-Йорк',
        answers: ['0.01%', '0.05%', '0.08%', '0.09%'],
        correctAnswer: 2,
      },
    ],
  },
  // {
  //   name: 'Sample Test 2',
  //   state: 'Florida',
  //   questions: [
  //     {
  //       question:
  //         'When two vehicles meet on a steep mountain road where neither can pass, which vehicle has the right-of-way?',
  //       answers: ['The vehicle traveling downhill', 'Both vehicles', 'The vehicle traveling uphill'],
  //       correctAnswer: 2,
  //     },
  //     {
  //       question: 'If you are having vehicle trouble and you need to stop, what is the first thing you should do?',
  //       answers: [
  //         'Stop your vehicle over a hill or just around a curve for visibility.',
  //         'Pull of the road and away from traffic.',
  //         'Lift the hood to signal an emergency.',
  //       ],
  //       correctAnswer: 1,
  //     },
  //   ],
  // },
]

// each test is an array of objects (question, choices, and correct answer)
//
const sampleData = [
  [
    {
      question:
        'When two vehicles meet on a steep mountain road where neither can pass, which vehicle has the right-of-way?',
      answers: [
        'The vehicle traveling downhill',
        'Both vehicles',
        'The vehicle traveling uphill',
      ],
      correctAnswer: 2,
    },
    {
      question:
        'If you are having vehicle trouble and you need to stop, what is the first thing you should do?',
      answers: [
        'Stop your vehicle over a hill or just around a curve for visibility.',
        'Pull of the road and away from traffic.',
        'Lift the hood to signal an emergency.',
      ],
      correctAnswer: 1,
    },
  ],
  [
    {
      question: 'What is the speed limit on the freeway',
      answers: ['45', '55', '65', 'There is no speed limit in the freeway'],
      correctAnswer: 2,
    },
    {
      question:
        'You must not follow within _______ of any emergency vehicle with a siren or flashing lights.',
      answers: ['300 feet', '200 feet', '100 feet'],
      correctAnswer: 0,
    },
    {
      question:
        'You must not follow within _______ of any emergency vehicle with a siren or flashing lights.',
      answers: ['300 feet', '200 feet', '100 feet'],
      correctAnswer: 0,
    },
  ],
]
