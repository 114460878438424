import React from 'react'
import Button from '../Atoms/Button'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import rightArrow from '../../img/images/rightArrow.png'
// images
import glassesGuySmallImg from '../../img/images/glassesGuy.png'
import glassesGuyFullImg from '../../img/images/fullPicGuyGlasses.svg'
import blob1 from '../../img/images/blobs/blob1.png'
import blob2 from '../../img/images/blobs/blob2.png'
import blob3 from '../../img/images/blobs/blob3.png'
import blob5 from '../../img/images/blobs/blob5.svg'
import noteBook from '../../img/images/notebook.png'
import interactiveLearning from '../../img/images/interactiveLearning.png'
import mm1 from '../../img/images/MixMatch.svg'
import mm2 from '../../img/images/mixMatch2.svg'

export default function Product() {
  return (
    <Component>
      <TwoDisplayContainers>
        <DisplayCard
          imgSrc={noteBook}
          imgAlt={'learn to become an optician'}
          // cardTitle={'All in One Guide '}
          //           cardParagraph={`Your passion for helping people with our organization of
          // content will help you to get your license in no time. Let us
          // give your the guidance that is needed to become the next
          // optician.`}
          cardTitle={'Все в одном месте'}
          cardParagraph={`Теперь вам не нужно будет прыгать с сайта на сайт для поиска решения по интересующему вопросу, потому что теперь все находится в одном месте. Мы сделали изучение и подготовку к теоретическому экзамену DMV простой и удобной для вас!
          `}
          linkTo={'/test-prep'}
          linkTitle={'Начать изучение'}
          // linkTitle={'Learn More'}
        />
        <Blob4 src={blob2} alt='abo test' />
        <DisplayCard
          imgSrc={interactiveLearning}
          imgAlt={'interactive learning'}
          cardTitle={'Интерактивное обучение'}
          cardParagraph={`Позволит вам активно участвовать в процессе изучения нового материала, а не просто пассивно принимать информацию. Повысит вашу мотивацию и увлеченность в получении новых знаний, так как теперь у вас будет возможность самостоятельно исследовать тему и делать по ней выводы. Кроме того, интерактивное обучение является более эффективным, так как вы можете сразу применять приобретенные знания, отрабатывая их на практике.`}
          //           cardTitle={'Interactive Learning'}
          //           cardParagraph={`Your passion for helping people with our organization of
          // content will help you to get your license in no time. Let us
          // give your the guidance that is needed to become the next
          // optician.`}
          linkTo={'/test-prep'}
          linkTitle={'Начать изучение'}
          // linkTitle={'Learn More'}
        />
      </TwoDisplayContainers>
      <TwoByTwoSection>
        <TwoByTwoFirstDiv>
          {/* <h2>Mix and Match</h2> */}
          <h2>Множественный выбор вопросов</h2>
          <img src={mm1} alt='abo practice text' />
          {/* <p>
            Your passion for helping people with our organization of content
            will help you to get your license in no time. Let us give your the
            guidance that is needed to become the next optician.{' '}
          </p> */}
          <p>
            <ul>
              <li>Позволяют быстро и эффективно проверить свои знания</li>
              <li>
                Помогают развивать навык быстрого анализа информации и принятия
                решений
              </li>
              <li>
                Способствуют определению ключевых тем, которым стоит уделить
                особое внимание при подготовке к экзамену
              </li>
            </ul>
          </p>
          <div style={{ marginTop: 30 }} />
          <Button color='blue'>
            {/* <Link to='/test-prep'>Learn More</Link> */}
            <Link to='/test-prep'>Начать изучение</Link>
          </Button>
          <Blob5 src={blob5} alt='abo test ready' />
        </TwoByTwoFirstDiv>
        <TwoByTwoSecondBlockImg src={mm2} alt='get ready for abo test' />
        <TwoByTwoSecondDiv>
          {/* <h2>Quizzes and Progress</h2>
          <p>
            Your passion for helping people with our organization of content
            will help you to get your license in no time. Let us give your the
            guidance that is needed to become the next optician.
          </p> */}
          <h2>Тесты и ваш прогресс</h2>
          <p>
            Изучайте правила дорожного движения и сразу же тренируйтесь. Теперь
            у вас есть возможность закрепить усвоенный материал, сразу после
            изучения новой информации, а также отследить прогресс в обучении!
          </p>
          <div style={{ marginTop: 30 }} />
          <Button color='blue'>
            {/* <Link to='/test-prep'>Try Now</Link> */}
            <Link to='/test-prep'>Начать сейчас</Link>
          </Button>
          <div style={{ marginTop: 100 }} />
        </TwoByTwoSecondDiv>
      </TwoByTwoSection>
    </Component>
  )
}
const Component = styled.div`
  align-self: center;
  width: 80%;
`
const H1 = styled.h1`
  font-size: 160%;
  width: 80%;
  margin: 150px 0 0 0px;
  color: #707070;
`

const P = styled.p`
  color: #657783b9;
  width: 60%;
  margin: 25px 0 0 0px;
  /* laptops 1200px - largest screen */
  @media only screen and (min-width: 1200px) {
    color: #657783b9;
    width: 45%;
    margin: 25px 0 0 0px;
  }
`

const Blob1 = styled.img`
  position: absolute;
  z-index: -1;
  top: 0px;
  left: 0;
  /* laptops 992px - largest screen */
  @media only screen and (min-width: 992px) {
    position: absolute;
    top: 0px;
    left: 0;
  }
`

const Blob2 = styled.img`
  position: absolute;
  left: 0;
  margin-top: 60px;
`

const Blob3 = styled.img`
  position: absolute;
  right: 0;
  top: 600px;
`

const Blob4 = styled.img`
  opacity: 0.1;
  transform: rotate(180deg);
  position: absolute;
  right: 0;
  top: 1500px;

  /* laptops 992px - largest screen */
  @media only screen and (min-width: 992px) {
    position: absolute;
    right: 0;
    top: 1000px;
  }
`

const Blob5 = styled.img`
  position: absolute;
  right: 0;
  /* laptops 992px - largest screen */
  @media only screen and (min-width: 992px) {
    position: absolute;
    right: 0;
    top: 1900px;
    height: 170px !important;
  }
`

const TwoByTwoSection = styled.div`
  margin: auto;

  h2 {
    font-size: 140%;
    margin: 100px 0 0 0;
    color: #616161;
  }
  p {
    color: #657783b9;
    margin: 25px 0 0 0px;
  }
`

const TwoByTwoFirstDiv = styled.div`
  img {
    display: none;
  }
  /* ipads 768px-1200px  */
  @media only screen and (min-width: 768px) {
    p {
      margin: 25px 0 0 0px;
      width: 50%;
    }
    img {
      display: inline-block;
      height: 200px;
      float: right;
    }
  }
  /* laptops 992px - largest screen */
  @media only screen and (min-width: 992px) {
    img {
      height: 300px;
      margin-top: -100px;
      margin-bottom: 70px;
    }
  }
`

const TwoByTwoSecondDiv = styled.div`
  /* ipads 768px-1200px  */
  @media only screen and (min-width: 768px) {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    text-align: right;
    width: 70%;
    p {
      width: 85%;
    }
  }
  /* laptops 992px - largest screen */
  @media only screen and (min-width: 992px) {
    width: 65%;
  }
`

const TwoDisplayContainers = styled.div`
  /* laptops 992px - largest screen */
  @media only screen and (min-width: 992px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`

const TwoByTwoSecondBlockImg = styled.img`
  display: none;
  /* ipads 768px-1200px  */
  @media only screen and (min-width: 768px) {
    display: inline-block;
    height: 200px;
    float: left;
    margin-top: 120px;
  }
  /* laptops 992px - largest screen */
  @media only screen and (min-width: 992px) {
    display: inline-block;
    height: 300px;
  }
`

//////////

export function DisplayCard(props) {
  return (
    <Container>
      <Img src={props.imgSrc} alt={props.imgAlt} />
      <H2>{props.cardTitle}</H2>
      <CardParagraph>{props.cardParagraph}</CardParagraph>

      <Link className='cardLink' to={props.linkTo}>
        {props.linkTitle}
        <img className='rightArrow' src={rightArrow} alt='right arrow' />
      </Link>
    </Container>
  )
}

const Container = styled.div`
  margin: 40px 30px;
  padding: 40px 10px 10px 10px;
  width: 80%;
  height: auto;
  border-radius: 15px;
  box-shadow: 3px 3px 10px rgba(87, 90, 113, 0.4);
  z-index: 10;
  .cardLink {
    margin-left: 30px;
    margin-bottom: 60px;
    color: #e53167;
    display: block;
    text-decoration: none;
  }
  .rightArrow {
    vertical-align: middle;
    margin-left: 8px;
  }
  /* laptops 992px - largest screen */
  @media only screen and (min-width: 992px) {
    width: 400px;
    display: inline-block;
  }
`

const Img = styled.img`
  height: 141px;
  width: auto;
  display: block;
  margin: auto;
`

const H2 = styled.h2`
  margin-top: 60px;
  margin-left: 30px;
  margin-right: 30px;
  display: block;
  font-size: 120%;
`

const CardParagraph = styled.p`
  margin-top: 13.5px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  display: block;
  opacity: 0.55;
`
