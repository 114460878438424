import React from 'react'
import styled from 'styled-components'
import Button from '../Atoms/Button'
import Input from '../Atoms/Input'

export default function EmailForm() {
  return (
    <Form name='collect_email' data-netlify='true' method='POST'>
      <input type='hidden' name='form-name' value='collect_email' />
      <Input placeholder='name' type='default' name='collect-email_name' />
      <Input placeholder='email' type='email' name='collect-email_email' />
      <Button color='blue' type='submit'>
        {/* Get Deals */}
        Подписаться на рассылку
      </Button>
    </Form>
  )
}

const Form = styled.form`
  width: 90%;
  display: flex;
  justify-content: center;
  align-self: center;
  gap: 10px;
  flex-wrap: wrap;
  Input {
    min-width: 300px;
    border: 1px solid #389bff;
  }
  margin: 75px 10px; ;
`
