import React from 'react'
import styled from 'styled-components'
const reviews = [
  {
    src: `https://farsighttechnologies.com/wp-content/uploads/2020/09/AC.jpg`,
    review: `Я хочу написать отзыв о тесте на водительские права, который я проходил недавно. Я использовал подготовительный курс, который оказался очень полезным. Материал был представлен понятно и доступно, и я чувствовал себя уверенно на экзамене. Также были предоставлены онлайн-тесты, которые помогли мне определить свои слабые места и улучшить свои знания. Я рекомендую этот курс всем, кто готовится к тесту на водительские права. Он оказался очень полезным и эффективным.`,
    name: 'Александр',
    company: 'CPF India',
  },
  {
    src: `https://farsighttechnologies.com/wp-content/uploads/2020/09/AC.jpg`,
    review: `Я очень рад, что решил использовать программу подготовки к экзамену на водительские права в DMV. Она была очень полезной для меня, потому что она помогла мне лучше понять ПДД. Я оценил интерактивные тесты, которые помогали мне проверить свои знания, и объяснения, которые давались для каждого вопроса. Общее, я считаю, что это было очень ценное инвестирование в мою будущую карьеру водителя. Я рекомендую эту программу всем, кто готовится к экзамену на водительские права в DMV.`,
    name: 'Сергей',
    company: 'CPF India',
  },
  {
    src: `https://farsighttechnologies.com/wp-content/uploads/2020/09/AC.jpg`,
    review: `Я недавно проходил тест на получение водительского удостоверения в ДМВ и использовал программу подготовки к экзамену. Я доволен результатами, которые дала эта программа. Она помогла мне освоить необходимый материал и подготовиться к тесту. В программе были полезные инструкции и практические упражнения, которые помогли мне улучшить мои навыки вождения. Я рекомендую эту программу другим людям, которые хотят получить водительское удостоверение в ДМВ.`,
    name: 'Максим',
    company: 'CPF India',
  },
  {
    src: `https://farsighttechnologies.com/wp-content/uploads/2020/09/AC.jpg`,
    review: `Я использовал подготовительный курс к тесту на водительские права в ДМВ, и он оказался очень полезным. Материалы были представлены понятно и доступно, и упражнения помогли мне лучше запомнить информацию. Преподаватели были также очень полезными, и они давали мне индивидуальную помощь, когда у меня были вопросы. Я рекомендую этот курс всем, кто планирует сдавать тест на водительские права в ДМВ.`,
    name: 'Иван',
    company: 'CPF India',
  },
]

export default function Reviews() {
  return (
    <AllReviews>
      {reviews.map((el, i) => (
        <Review
          key={i}
          review={el.review}
          name={el.name}
          // company={el.company}
          // src={el.src}
        />
      ))}
    </AllReviews>
  )
}

function Review({ review, name, company, src }) {
  return (
    <Component>
      <div className='sdtestBg2' />
      <div className='sdtestBg3' />
      <div className='slideshow'>
        {src && (
          <div className='thumbnail'>
            <img src={src} />
          </div>
        )}
        <h1 className='sdCustomSliderHeadig'>{review}</h1>
        <p className='SdClientName'>{name}</p>
        <p className='SdClientDesc'>{company}</p>
      </div>
    </Component>
  )
}

const AllReviews = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`
const Component = styled.div`
  position: relative;
  display: inline-flex;
  margin: 40px 20px;

  .sdtestBg3 {
    height: 100%;
    width: 250px;
    background: #fff;
    position: absolute;
    bottom: -40px;
    left: 50px;
    border-radius: 20px;
    box-shadow: 0px 18px 52.8537px rgb(215 228 249 / 50%);
    z-index: 1;
  }
  .sdtestBg2 {
    height: 100%;
    width: 300px;
    background: #fff;
    position: absolute;
    bottom: -22px;
    left: 25px;
    border-radius: 20px;
    box-shadow: 0px 18px 52.8537px rgb(215 228 249 / 50%);
    z-index: 2;
  }

  .slideshow {
    width: 350px;
    padding: 5px;
    position: relative;
    min-height: 300px;
    height: auto;
    background: #fff;
    box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.5);
    border-radius: 20px;
    background-image: url('https://farsighttechnologies.com/wp-content/uploads/2021/03/quote1.png'),
      url('https://farsighttechnologies.com/wp-content/uploads/2021/03/quote.png');
    background-position: top 60px left 15px, bottom 20% right 15px;
    background-repeat: no-repeat;
    background-size: 40px, 40px;
    z-index: 3;
  }

  .pagingInfo {
    position: absolute;
    bottom: 0;
    z-index: 999;
  }
  .sdCustomSliderHeadig {
    color: #94a2b3;
    font-family: 'Poppins', Sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.6em;
    text-align: center;
    width: 75%;
    margin: auto;
    padding: 20px;
  }

  .thumbnail img {
    display: flex;
    align-self: center;
    height: 55px;
    width: 55px;
    border-radius: 50%;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 15px;
  }

  .SdClientName {
    text-align: center;
    color: #272d4e;
    font-family: 'Poppins', Sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4em;
    margin-top: 15px;
  }
  .SdClientDesc {
    text-align: center;
    color: #7854f7;
    font-family: 'Poppins', Sans-serif;
    font-size: 14px;
    line-height: 1.4em;
    margin-bottom: 10px;
  }
`
