import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export default function Nav() {
  return (
    <Navbar>
      <LeftSide>
        <Link to='/'>Главная</Link>
      </LeftSide>
      <RightSide>
        {/* <Link to='/test-prep'>Take Exam</Link> */}
        <Link to='/test-prep'>Начать тест</Link>
      </RightSide>
    </Navbar>
  )
}

const Navbar = styled.nav`
  font-size: 125%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-top: 50px;

  a {
    color: white;
    padding: 10px;
    border-radius: 10px;
    transition: 0.2s;
  }
  a:hover {
    cursor: pointer !important;
    box-shadow: 0px 3px 5px white;
  }
`

const LeftSide = styled.div`
  margin-left: -10px;
`
const RightSide = styled.div`
  margin-right: -10px;
`
