import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import Button from '../Atoms/Button'
import Nav from './Nav'

import landingPhotoL from '../../img/car_landing_large.svg'
import landingPhotoS from '../../img/car_landing_small.svg'

const landingPicAnimate = {
  initial: { opacity: 0, x: 200 },
  animate: { opacity: 1, x: 0 },
  transition: { duration: 0.5 },
}

export default function LandingPage() {
  return (
    <div className='background-picture'>
      <AnimationArea />
      <div className='landingDiv  mw-90p mt-30px'>
        <Nav />
        <div className='landing-content'>
          <div>
            <motion.img
              {...landingPicAnimate}
              className='landingPhoto-l'
              src={landingPhotoL}
              alt='Driver License'
            />
            <motion.img
              {...landingPicAnimate}
              className='landingPhoto-s'
              src={landingPhotoS}
              alt='Driver License'
            />

            <motion.div
              initial={{ opacity: 0, x: -200 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              <h1 style={{ color: 'white' }} className='h1'>
                {/* DMV License Test */}
                Тесты DMV на русском языке
              </h1>
              <p>
                Подготовка к теоретическому тесту DMV
                {/* How to study for license <br />
                the smart way */}
              </p>

              <Link className='project-button' to='/test-prep'>
                {/* <Button move>Take Exam</Button> */}
                <Button move>Начать тест</Button>
              </Link>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const AnimationArea = () => (
  <Area>
    <ul className='box-area'>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </Area>
)

const Area = styled.div`
  .box-area {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .box-area li {
    position: absolute;
    display: block;
    list-style: none;
    width: 25px;
    height: 25px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 20s linear infinite;
    bottom: -150px;
  }
  .box-area li:nth-child(1) {
    left: 86%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }
  .box-area li:nth-child(2) {
    left: 12%;
    width: 30px;
    height: 30px;
    animation-delay: 1.5s;
    animation-duration: 10s;
  }
  .box-area li:nth-child(3) {
    left: 70%;
    width: 100px;
    height: 100px;
    animation-delay: 5.5s;
  }
  .box-area li:nth-child(4) {
    left: 42%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 15s;
  }
  .box-area li:nth-child(5) {
    left: 65%;
    width: 40px;
    height: 40px;
    animation-delay: 0s;
  }
  .box-area li:nth-child(6) {
    left: 15%;
    width: 110px;
    height: 110px;
    animation-delay: 3.5s;
  }
  @keyframes animate {
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 1;
    }
    100% {
      transform: translateY(-800px) rotate(360deg);
      opacity: 0;
    }
  }
`
