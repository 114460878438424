import React from 'react'
import Button from './Button'
import styled from 'styled-components'
import '../Pages/home.css'
import SelectStatePage from './SelectStatePage'

export default function MultipleChoice({
  selectedAnswers,
  setSelectedAnswers,
  currQuestionIndex,
  setCurrQuestionIndex,
  answerClickHandler,
  nextHandleClick,
  previousHandleClick,
  questions,
  selectedState,
  setSelectedState,
}) {
  return (
    <Card>
      {selectedState ? (
        <>
          <div className='title'>
            {currQuestionIndex + 1}. {questions[currQuestionIndex].question}
          </div>
          <ul>
            {questions[currQuestionIndex].answers.map((choice, index) => {
              let className = '' // 3 states: 'correct' 'incorrect' ''
              let disabled = false
              if (selectedAnswers[currQuestionIndex] !== null) {
                disabled = true
                if (index === questions[currQuestionIndex].correctAnswer) className = 'correct'
                else if (index === selectedAnswers[currQuestionIndex]) className = 'incorrect'
              }
              return (
                <Answer key={index} status={className} disabled={disabled} onClick={() => answerClickHandler(index)}>
                  <div className='outer'>
                    <div className='inner'></div>
                  </div>
                  {choice}
                </Answer>
              )
            })}
          </ul>
          {/* only show the previous button if currQuestionIndex > 0 */}
          <div className='buttons'>
            <Button
              style={{ visibility: currQuestionIndex > 0 ? 'visible' : 'hidden' }}
              handleClick={previousHandleClick}
            >
              {'<'}
            </Button>

            {currQuestionIndex + 1 !== questions.length && selectedAnswers[currQuestionIndex] !== null && (
              <Button handleClick={nextHandleClick} color='blue'>
                {'>'}
              </Button>
            )}
          </div>
        </>
      ) : (
        <SelectStatePage setSelectedState={setSelectedState} />
      )}
    </Card>
  )
}
const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
  padding-top: 40px;
  background-color: white;
  border-radius: 12px;
  gap: 1rem;
  .title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  ul {
    margin-bottom: 20px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    gap: 5px;
  }

  @media only screen and (max-width: 580px) {
    padding: 20px 25px;
  }
`
const Answer = styled.li`
  border-radius: 6px;
  padding: 10px;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: ${({ status }) => (status === 'incorrect' ? 'line-through' : '')};
  margin-bottom: 1rem;

  .outer {
    transition: 0.2s;
    background-color: ${(props) =>
      props.status === 'correct' ? 'green' : props.status === 'incorrect' ? 'red' : 'white'};
    min-width: 1rem;
    min-height: 1rem;
    border-radius: 50%;
    border: 1px solid #c3c2d3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inner {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
  }
  :hover {
    background-color: #edf0fa;
    cursor: pointer;

    .outer {
      background-color: ${(props) => (props.status === 'correct' ? 'green' : !props.disabled ? '#c3c2d3' : '')};
    }
    .inner {
      background-color: ${(props) => (props.status === 'correct' ? 'green' : !props.disabled ? '#edf0fa' : '')};
    }
  }
`
