//TODO: company name

import React from 'react'

export default function Footer() {
  return (
    <div className='footer'>
      <footer>
        <p>
          © 2013-{new Date().getFullYear()} DMV License Test. All Rights
          Reserved.
        </p>
      </footer>
    </div>
  )
}
