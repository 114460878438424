import React from 'react'
import Button from '../Atoms/Button'
import Input from '../Atoms/Input'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment, faXmark } from '@fortawesome/free-solid-svg-icons'

export default function Feedback() {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <Component isOpen={isOpen}>
      {isOpen ? (
        <div className='feedback_content'>
          <FontAwesomeIcon
            onClick={() => setIsOpen(false)}
            className='close'
            size='lg'
            icon={faXmark}
          />
          <form name='feedback' data-netlify='true' method='POST'>
            <input type='hidden' name='form-name' value='feedback' />
            <h2>Feedback</h2>
            <Input
              style={{ width: '100%' }}
              placeholder='email'
              type='email'
              name='feedback_email'
            />
            <textarea placeholder='message/feedback' name='feedback_message' />
            <Button color='blue' type='submit'>
              Submit
            </Button>
          </form>
        </div>
      ) : (
        <FontAwesomeIcon
          onClick={() => setIsOpen(true)}
          className='icon'
          // size='2x'
          icon={faComment}
        />
      )}
    </Component>
  )
}

const Component = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  ${({ isOpen }) =>
    isOpen
      ? ''
      : ` height: 72px;
  width: 72px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;`}

  .icon {
    padding: 15px;
    box-shadow: 2px 5px 5px #67666693;
    display: flex;
    background-color: white;
    border-radius: 50%;
    line-height: 2;
    color: #4866ffb6;
    cursor: pointer;
    height: 30px;
  }

  .feedback_content {
    padding: 20px;
    box-shadow: 2px 5px 10px #67666693;
    border-radius: 12px;
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    color: black;
    height: 400px;
    width: 250px;
    background: white;
    line-height: 2;
    box-sizing: border-box;
    height: auto;
    h2 {
      color: #4866ffb6;
    }
  }
  .close {
    position: absolute;
    top: 0px;
    right: 10px;
    color: #4866ffb6;
    cursor: pointer;
    padding: 5px;
  }

  textarea {
    margin: 5px 0px;
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    border: 1px solid #7e7d7a64;
    border-radius: 5px;
    display: flex;
    height: 200px;
    margin-bottom: 20px;
  }
`
