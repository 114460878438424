import React, { useEffect, useState } from 'react'
import MultipleChoice from '../Atoms/MultipleChoice'
import Nav from '../Atoms/Nav'
import ConfigBar from '../Atoms/ConfigBar'
import styled from 'styled-components'
import { tests } from '../../data'
import useLocalStorage from '../../Hooks/useLocalStorage'

// we need to keep track of the selected test, the current question, and the selected answers
export default function TestPrep() {
  const [selectedState, setSelectedState] = useLocalStorage('selectedState', '')
  const [currQuestionIndex, setCurrQuestionIndex] = useLocalStorage('currQuestionIndex', 0)
  const [currTestIndex, setCurrTestIndex] = useLocalStorage('currTestIndex', 0, (storedTestIndex) => {
    return parseInt(storedTestIndex) > tests.length - 1 ? 0 : storedTestIndex
  })
  const [questions, setQuestions] = useState(tests[currTestIndex].questions)
  const [allAnswers, setAllAnswers] = useLocalStorage(
    'selectedAnswers',
    Array(tests.length)
      .fill(null)
      .map((x, i) => Array(tests[i].questions.length).fill(null))
  )
  const [selectedAnswers, setSelectedAnswers] = useState(allAnswers[currTestIndex])

  useEffect(() => {
    let newQuestions = tests[currTestIndex].questions

    let latestQuestionIndex = getLatestQuestionIndex()

    setCurrQuestionIndex(latestQuestionIndex)
    setQuestions(newQuestions)
    setSelectedAnswers(allAnswers[currTestIndex])
  }, [currTestIndex])

  useEffect(() => {
    // change the currTestIndex to first test in that state
    for (let i = 0; i < tests.length; i++) {
      if (tests[i].state === selectedState) {
        setCurrTestIndex(i)
        return
      }
    }
  }, [selectedState])
  useEffect(() => {
    // ** This checking is done so we don't run into issues when updating tests and pushing new tests
    // verify that tests.length === selectedAnswers.length
    // for each test the lengths are equal
    // if not reset the selected answers, currTestIndex, and currQuestionIndex
    if (allAnswers.length !== tests.length) {
      resetLocalStorage()
      return
    }
    for (let i = 0; i < tests.length; i++) {
      if (tests[i].questions.length !== allAnswers[i].length) {
        resetLocalStorage()
        return
      }
    }
  }, [])

  // when switching tests, set the question index to latest unanswered question
  const getLatestQuestionIndex = () => {
    let currQuestionIndex = 0
    for (let i = 0; i < allAnswers[currTestIndex].length - 1; i++) {
      if (typeof allAnswers[currTestIndex][i] === 'number') {
        currQuestionIndex = i + 1
      }
    }
    return currQuestionIndex
  }

  const resetLocalStorage = () => {
    setAllAnswers(
      Array(tests.length)
        .fill(null)
        .map((x, i) => Array(tests[i].questions.length).fill(null))
    )
    setCurrTestIndex(0)
    setCurrTestIndex(0)
  }

  const answerClickHandler = (index) => {
    if (selectedAnswers[currQuestionIndex] !== null) return
    let newAnswers = [...allAnswers]
    newAnswers[currTestIndex][currQuestionIndex] = index
    setAllAnswers(newAnswers)
    setSelectedAnswers(allAnswers[currTestIndex])
  }

  const nextHandleClick = () => {
    if (selectedAnswers[currQuestionIndex] !== null && currQuestionIndex + 1 < questions.length) {
      setCurrQuestionIndex((curr) => curr + 1)
    }
  }
  const previousHandleClick = () => {
    if (currQuestionIndex > 0) {
      setCurrQuestionIndex((curr) => curr - 1)
    }
  }
  const resetAnswers = () => {
    // reseting the answers of the current test
    // FIXED bug where when the data.js gets updated this function wouldn't properly reset
    let newAnswers = [...allAnswers]
    newAnswers[currTestIndex] = new Array(tests[currTestIndex].questions.length).fill(null)
    setAllAnswers(newAnswers)
    setSelectedAnswers(newAnswers[currTestIndex])
    setCurrQuestionIndex(0)
  }
  return (
    <Page>
      <Nav />
      <div className='container'>
        <ConfigBar
          selectedState={selectedState}
          setSelectedState={setSelectedState}
          setCurrQuestionIndex={setCurrQuestionIndex}
          selectedAnswers={selectedAnswers}
          questions={questions}
          currQuestionIndex={currQuestionIndex}
          resetAnswers={resetAnswers}
          setCurrTestIndex={setCurrTestIndex}
          currTestIndex={currTestIndex}
        />
        <MultipleChoice
          selectedState={selectedState}
          setSelectedState={setSelectedState}
          selectedAnswers={selectedAnswers}
          setSelectedAnswers={setSelectedAnswers}
          currQuestionIndex={currQuestionIndex}
          setCurrQuestionIndex={setCurrQuestionIndex}
          answerClickHandler={answerClickHandler}
          nextHandleClick={nextHandleClick}
          previousHandleClick={previousHandleClick}
          questions={questions}
          resetAnswers={resetAnswers}
        />
      </div>
    </Page>
  )
}

const Page = styled.div`
  box-sizing: border-box;
  padding: 0 10%;
  background: linear-gradient(#389bff, #2369ffdf);
  color: black;
  min-height: 100vh;
  width: 100vw;

  .container {
    max-width: 800px;
    margin: auto;
    padding-bottom: 100px;
  }

  @media screen and (max-width: 500px) {
    padding: 0 5%;
  }
`
