import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookOpenReader, faTrafficLight, faSquareCheck } from '@fortawesome/free-solid-svg-icons'

const aboutAnimate = {
  initial: { opacity: 0, scale: 0.7 },
  whileInView: { opacity: 1, scale: 1 },
  transition: { duration: 0.5 },
  viewport: { once: true },
}
let data = [
  {
    id: 0,
    icon: faBookOpenReader,
    title: "Read the Driver's Manual",
    description: `We provide the most recent version online, but you can also download the PDF file to study offline and on the go.`,
  },
  {
    id: 1,
    icon: faTrafficLight,
    title: 'Take engaging practice tests',
    description: `They cover every section of the driver's manual and literally "over-prepare" you, so the official exam will seem easy.`,
  },
  {
    id: 2,
    icon: faSquareCheck,
    title: 'Pass your official DMV test',
    description: `Once you've practiced enough, make an appointment at the DMV. You'll be ready to pass the official exam the first time!`,
  },
]

export default function About() {
  return (
    <Component {...aboutAnimate}>
      <h2 className="section_title">What We Do</h2>
      <div className="cards">
        {data.map(({ icon, title, description, id }) => (
          <Card key={id} icon={icon} title={title} description={description} />
        ))}
      </div>
    </Component>
  )
}

function Card({ icon, title, description }) {
  return (
    <StyledCard>
      <FontAwesomeIcon className="icon" size="4x" color="#4866ffb6" icon={icon} />
      <div className="title">{title}</div>
      <div className="description">{description}</div>
    </StyledCard>
  )
}

const StyledCard = styled.div`
  box-sizing: border-box;
  width: 350px;
  height: 350px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: 5px 10px 10px 10px rgb(215 228 249 / 50%);
  transition: 0.5s ease-in-out;
  padding: 30px;
  margin: 20px;
  border-radius: 15px;

  .icon {
    margin-bottom: 30px;
  }
  .title {
    color: #4867ff;
    font-weight: 550;
    margin-bottom: 15px;
  }

  .description {
    line-height: 1.8;
  }
`

const Component = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  margin: 50px 10%;

  .section_title {
    margin-bottom: 35px;
  }
  .cards {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  }
`
