import React from 'react'
import styled from 'styled-components'

export default function Input({ style, placeholder, type, name, value }) {
  return <StyledInput style={style} value={value} placeholder={placeholder} type={type} name={name} />
}

const StyledInput = styled.input`
  margin: 5px 0px;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #7e7d7a64;
  border-radius: 5px;
`
