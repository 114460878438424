import React from 'react'
import styled from 'styled-components'
import { tests } from '../../data'

const SelectStatePage = ({ setSelectedState }) => {
  const states = [
    { name: 'Alabama', count: 0 },
    { name: 'Alaska', count: 0 },
    { name: 'Arizona', count: 0 },
    { name: 'Arkansas', count: 0 },
    { name: 'California', count: 0 },
    { name: 'Colorado', count: 0 },
    { name: 'Connecticut', count: 0 },
    { name: 'Delaware', count: 0 },
    { name: 'Florida', count: 0 },
    { name: 'Georgia', count: 0 },
    { name: 'Hawaii', count: 0 },
    { name: 'Idaho', count: 0 },
    { name: 'Illinois', count: 0 },
    { name: 'Indiana', count: 0 },
    { name: 'Iowa', count: 0 },
    { name: 'Kansas', count: 0 },
    { name: 'Kentucky', count: 0 },
    { name: 'Louisiana', count: 0 },
    { name: 'Maine', count: 0 },
    { name: 'Maryland', count: 0 },
    { name: 'Massachusetts', count: 0 },
    { name: 'Michigan', count: 0 },
    { name: 'Minnesota', count: 0 },
    { name: 'Mississippi', count: 0 },
    { name: 'Missouri', count: 0 },
    { name: 'Montana', count: 0 },
    { name: 'Nebraska', count: 0 },
    { name: 'Nevada', count: 0 },
    { name: 'New Hampshire', count: 0 },
    { name: 'New Jersey', count: 0 },
    { name: 'New Mexico', count: 0 },
    { name: 'New York', count: 0 },
    { name: 'North Carolina', count: 0 },
    { name: 'North Dakota', count: 0 },
    { name: 'Ohio', count: 0 },
    { name: 'Oklahoma', count: 0 },
    { name: 'Oregon', count: 0 },
    { name: 'Pennsylvania', count: 0 },
    { name: 'Rhode Island', count: 0 },
    { name: 'South Carolina', count: 0 },
    { name: 'South Dakota', count: 0 },
    { name: 'Tennessee', count: 0 },
    { name: 'Texas', count: 0 },
    { name: 'Utah', count: 0 },
    { name: 'Vermont', count: 0 },
    { name: 'Virginia', count: 0 },
    { name: 'Washington', count: 0 },
    { name: 'West Virginia', count: 0 },
    { name: 'Wisconsin', count: 0 },
    { name: 'Wyoming', count: 0 },
  ]
  for (let test of tests) {
    for (let i = 0; i < states.length; i++) {
      if (states[i].name.toLowerCase() === test.state.toLowerCase())
        states[i].count++
    }
  }
  states.sort((a, b) => b.count - a.count)

  const handleClick = (state) => {
    if (state.count > 0) setSelectedState(state.name)
    else alert(`No tests for ${state.name} yet`)
  }
  return (
    <StyledDiv>
      <h1>Пожалуйста, выберите свой штат</h1>
      {/* <h1>Please Select Your State</h1> */}
      <ul>
        {states.map((state) => {
          if (state.count > 0) {
            return (
              <li
                onClick={() => handleClick(state)}
                key={state.name}
                title={state.name}
              >
                {state.name} ({state.count})
              </li>
            )
          }
        })}
      </ul>
      <p>*The number next to the state indicates number of tests</p>
    </StyledDiv>
  )
}

export default SelectStatePage

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  min-height: 300px;

  ul {
    align-self: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    list-style: none;
  }
  li {
    background-color: #389cff;
    color: white;
    border-radius: 10px;
    padding: 7px 15px;

    :hover {
      cursor: pointer;
      outline: auto;
    }
  }

  p {
    margin-top: auto;
  }
`
