import React from 'react'
import styled from 'styled-components'
const Button = React.forwardRef(
  ({ style, type, disabled, move, children, color, handleClick }, ref) => {
    return (
      <ButtonStyled
        style={style}
        link={children?.props?.to}
        type={type}
        disabled={disabled}
        onClick={handleClick}
        move={move}
        color={color}
        ref={ref}
      >
        {children}
      </ButtonStyled>
    )
  }
)
export default Button

const ButtonStyled = styled.button`
  a {
    color: white;
    background: inherit;
    padding: 13px 40px;
    border-radius: 30px;
  }

  ${({ link }) => (link ? '' : 'padding: 13px 40px;')}
  background-color: ${({ color }) => (color === 'blue' ? '#389bff' : color)};
  color: ${({ color }) => (color !== 'blue' ? '#389bff' : 'white')};
  border-radius: 30px;
  font-size: 100%;
  transition: 0.2s, visibility 0s;

  :hover {
    cursor: pointer;
    box-shadow: 3px 5px 10px rgba(255, 255, 255, 0.57);
    ${({ move }) => (move ? 'transform:translateY(-7px)' : '')};
  }
  :disabled {
    cursor: not-allowed;
  }
`
