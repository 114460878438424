import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClipboardQuestion,
  faFile,
  faRotate,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons'
import Button from './Button'

import { Modal } from 'antd'
import { tests } from '../../data'

export default function ConfigBar({
  setCurrQuestionIndex,
  selectedAnswers,
  questions,
  currQuestionIndex,
  resetAnswers,
  setCurrTestIndex,
  currTestIndex,
  selectedState,
  setSelectedState,
}) {
  const [showQuestionsTable, setShowQuestionsTable] = React.useState(false)
  const [showTestsTable, setShowTestsTable] = React.useState(false)
  const [restartModal, setRestartModal] = React.useState(false)

  const questionsTableRef = React.useRef(false)
  const questionsIconRef = React.useRef(false)

  const testsTableRef = React.useRef(false)
  const testsIconRef = React.useRef(false)

  React.useEffect(() => {
    function clickOutsideTable(e) {
      //if clicked outside container close modal
      if (
        showQuestionsTable &&
        questionsTableRef.current &&
        !questionsTableRef.current.contains(e.target) &&
        questionsIconRef.current &&
        !questionsIconRef.current.contains(e.target)
      ) {
        setShowQuestionsTable(false)
      } else if (
        showTestsTable &&
        testsTableRef.current &&
        !testsTableRef.current.contains(e.target) &&
        testsIconRef.current &&
        !testsIconRef.current.contains(e.target)
      ) {
        setShowTestsTable(false)
      }
    }
    document.addEventListener('click', clickOutsideTable, false)
    return () => document.addEventListener('click', clickOutsideTable, false)
  }, [showQuestionsTable, showTestsTable])

  const handleTableSquareClick = (i) => {
    setCurrQuestionIndex(i)
  }

  return (
    <Card
      showQuestionsTable={showQuestionsTable}
      showTestsTable={showTestsTable}
      selectedAnswers={selectedAnswers}
      currQuestionIndex={currQuestionIndex}
      numberOfQuestions={questions.length}
      selectedState={selectedState}
    >
      <StateButton onClick={() => setSelectedState('')}>
        {selectedState && <p>{selectedState}</p>}
        <FontAwesomeIcon icon={faAngleDown} />
      </StateButton>
      <Options>
        <Button
          ref={questionsIconRef}
          handleClick={() => setShowQuestionsTable((val) => !val)}
        >
          <FontAwesomeIcon
            className='icon questionsIcon'
            color='#389cff'
            icon={faClipboardQuestion}
          />
          <p>Вопросы</p>
        </Button>
        <Button
          ref={testsIconRef}
          handleClick={() => setShowTestsTable((val) => !val)}
        >
          <FontAwesomeIcon
            className='icon testsIcon'
            color='#389cff'
            icon={faFile}
          />
          <p>Тесты</p>
        </Button>
        <Button handleClick={() => setRestartModal(true)}>
          <FontAwesomeIcon className='icon' color='#389cff' icon={faRotate} />
          <p>Рестарт</p>
        </Button>
      </Options>

      {showQuestionsTable && selectedState && (
        <Table
          ref={questionsTableRef}
          isOpen={showQuestionsTable}
          showQuestionsTable={showQuestionsTable}
        >
          {questions.map((question, i) => (
            <Square
              disabled={selectedAnswers[i - 1] === null && i > 0}
              onClick={() => handleTableSquareClick(i)}
              key={i}
              correctAnswerIndex={question.correctAnswer}
              selectedAnswers={selectedAnswers}
              currQuestionIndex={currQuestionIndex}
              i={i}
            >
              {i + 1}
            </Square>
          ))}
        </Table>
      )}

      {showTestsTable && selectedState && (
        <Table
          ref={testsTableRef}
          isOpen={showTestsTable}
          showTestsTable={showTestsTable}
        >
          <Tests>
            {tests.map((test, i) => {
              if (test.state === selectedState) {
                return (
                  <Test
                    currTestIndex={currTestIndex}
                    tests={tests}
                    onClick={() => setCurrTestIndex(i)}
                    key={i}
                    i={i}
                  >
                    {test.name}
                  </Test>
                )
              }
            })}
          </Tests>
        </Table>
      )}

      <Modal
        // title='Restart'
        title='Перезапустить'
        centered
        open={restartModal}
        // okText='Restart'
        okText='Перезапустить'
        cancelText='Oтменить'
        onOk={() => {
          resetAnswers()
          setRestartModal(false)
        }}
        onCancel={() => setRestartModal(false)}
      >
        <p>Вы уверены, что хотите перезапустить тест?</p>
        <p>Ваш прогресс теста будет начат с самого начала.</p>
        {/* <p>Are you sure you want to restart the test?</p>
        <p>Your test progress will be lost.</p> */}
      </Modal>
    </Card>
  )
}

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  padding: 20px;
  background-color: white;
  border-radius: 12px;
  line-height: 2;
  margin-bottom: 3px;
  transition: 0.2s;
  .icon {
    transition: 0.2s;
    padding-right: 10px;
    height: 1.2rem;
  }
`

const StateButton = styled.button`
  position: absolute;
  transform: translateY(-110%);
  top: 0;
  left: 0;
  background-color: white;
  padding: 5px 10px;
  border: 1px solid #389cff;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: bold;
  color: #389cff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin-right: 5px;
  }
  :hover {
    cursor: pointer;
  }
`
const Options = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  flex-wrap: wrap;

  @media only screen and (max-width: 540px) {
    flex-direction: column;
  }

  button {
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 25px;
    @media only screen and (max-width: 588px) {
      width: 75%;
    }
  }
`

const Table = styled.div`
  max-height: 300px;
  overflow-y: scroll;
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
  min-width: 150px;
  max-width: 200px;
  position: absolute;
  top: 80px;
  left: ${({ showQuestionsTable, showTestsTable }) =>
    showQuestionsTable
      ? '32px'
      : showTestsTable
      ? '50%; transform: translateX(-50%) '
      : ''};
  background: white;
  z-index: 100;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 15px;
  transition: 0.2s;

  @media only screen and (max-width: 580px) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

const Square = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  width: 15px;
  padding: 20px;
  margin: 2px;
  border-radius: 10px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: ${({ correctAnswerIndex, i, selectedAnswers }) => {
    if (selectedAnswers[i] === null) return '#cac5c560'
    else if (correctAnswerIndex === selectedAnswers[i]) return '#aaf5b0'
    else if (correctAnswerIndex !== selectedAnswers[i]) return '#f48a8a'
    return 'green'
  }};

  border: ${({ i, currQuestionIndex }) => {
    if (i === currQuestionIndex) return '1px solid #007aff'
  }};
`

const Tests = styled.div`
  display: flex;
  flex-direction: column;
`
const Test = styled.div`
  color: blue;
  padding: 5px 10px;
  text-decoration: ${({ currTestIndex, i }) =>
    currTestIndex === i ? 'underline' : 'none'};
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`
